
import {computed, defineComponent} from "vue";
import moment from "moment";
import {useStore} from "vuex";
import { useI18n } from "vue-i18n/index";

export default defineComponent({
  name: "machine_activity",
  data() {
    return {
      percentage: 0,
    };
  },
  props: {
    machine: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const currentLanguage = computed(() => store.getters.getCurrentLanguage || localStorage.getItem('currentLanguage'));


    return {
      moment,
      currentLanguage
    };
  },
  created() {
    
    setTimeout(() => {
      this.percentage = this.machine.percentage;
    }, 1500);
  },
});
