
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import { Actions , Mutations } from "@/store/enums/StoreEnums";
import moment from "moment";
import {useI18n} from "vue-i18n";

export default defineComponent({
  name: "machine_timeline",
  data() {
    return {
      progress: 0,
    };
  },
  props: {
    machine: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const store = useStore();
    const { t } = useI18n();

    const currentLanguage = computed(() => store.getters.getCurrentLanguage || localStorage.getItem('currentLanguage'));
    store.commit(Mutations.SET_CURRENT_MACHINE_ACTIVITY, {});
    store.dispatch(Actions.GET_MACHINE_ACTIVITY, { id: props.machine.id });
    // get the current machine activities
    const activities = computed(() => {
      return store.getters.getMachineActivities;
    });

    return {
      moment,
      activities,
      currentLanguage
    };
  },
  created() {
    
    setTimeout(() => {
      this.progress = this.machine.completion_percentage;
    }, 1500);
  },
});
